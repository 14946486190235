function doHandleMonth(month) {
  //判断是否为1-9,如果是,前面加0
  var m = month;
  if (month.toString().length == 1) {
    m = "0" + month;
  }
  return m;
}
const getDay = (day) => {
  var today = new Date();
  var targetday = today.getTime() + 1000 * 60 * 60 * 24 * day;
  today.setTime(targetday); //关键代码
  var tYear = today.getFullYear(); //获取年份
  var tMonth = today.getMonth(); //获取月份
  var tDate = today.getDate(); //获取日期
  tMonth = doHandleMonth(tMonth + 1); //获取到的月份为0-11
  tDate = doHandleMonth(tDate);
  return tYear + "-" + tMonth + "-" + tDate;
};

export default getDay;
