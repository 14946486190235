<template>
  <div class="statistics">
    <van-tabs v-model="activeTab" @click="choseTab">
      <van-tab v-for="(tab, index) in tabTitle" :title="tab" :key="index">
        <div class="tab_content">
          <div class="statistics-data">
            <div class="statistics-data-card">
              <div class="data-title-box">
                <div class="data-title">客户统计</div>
                <div class="data-time">
                  <span
                    :class="{ 'time-chosen': timeActive === 7 }"
                    @click="choseTime(7)"
                    >近7日</span
                  >
                  <span class="time-split"> | </span>
                  <span
                    :class="{ 'time-chosen': timeActive === 30 }"
                    @click="choseTime(30)"
                    >近30天</span
                  >
                </div>
              </div>
              <div class="data-outline">
                <div
                  class="outline-item"
                  v-for="(item, index) in dataOutline"
                  :key="index"
                >
                  <span class="item-name">{{ item.name }}</span>
                  <span class="item-number">{{ item.number }}</span>
                </div>
              </div>
            </div>
          </div>
          <div class="chart-container">
            <div :id="'main' + index" class="data-line-pic"></div>
          </div>
        </div>
      </van-tab>
    </van-tabs>
  </div>
</template>
<script>
import { toThousands } from "@/globalWidget/utils/formatData.js";
import * as echarts from "echarts/core";
import {
  GridComponent,
  LegendComponent,
  TooltipComponent,
} from "echarts/components";
import { LineChart } from "echarts/charts";
import { CanvasRenderer } from "echarts/renderers";
import getDate from "@/globalWidget/utils/getDate.js";
export default {
  name: "customerStatistics",
  data() {
    return {
      activeTab: 0,
      tabTitle: ["全部", "我的"],
      timeActive: 7,
      dataOutline: [
        {
          name: "客户总数",
          number: 0,
        },
        {
          name: "新增客户数",
          number: 0,
        },
        {
          name: "流失客户数",
          number: 0,
        },
      ],
      xAxisData: [],
      totalCustom: [],
      newContactCnt: [],
      negativeFeedbackCnt: [],
      chartIcon: [
        "image://" + require("@/assets/images/total_chose.png") + "",
        "image://" + require("@/assets/images/new_chose.png") + "",
        "image://" + require("@/assets/images/negative_chose.png") + "",
      ],
    };
  },
  created() {
    this.get_chart_data();
  },
  mounted() {
    echarts.use([
      CanvasRenderer,
      GridComponent,
      LineChart,
      TooltipComponent,
      LegendComponent,
    ]);
    this.$nextTick(() => {
      this.drawLineEcharts(this.activeTab);
    });
    window.onresize = () => {
      this.$nextTick(() => {
        this.drawLineEcharts(this.activeTab);
      });
    };
  },
  methods: {
    get_chart_data() {
      this.$toast.loading({
        duration: 0,
        message: "加载中...",
        forbidClick: true,
      });
      let startDate = getDate(-this.timeActive);
      let endDate = getDate(-1);
      let params = {
        start_date: startDate,
        end_date: endDate,
      };
      if (this.activeTab == 1) {
        params.user_ids = localStorage.getItem("userId");
      }
      this.$ajax("get_chart_data", params).then((res) => {
        this.$toast.clear();
        if (res.data && res.data.code === 0) {
          const data = res.data.data;
          this.dataOutline[0].number = toThousands(data.customer_total);
          this.dataOutline[1].number = toThousands(data.new_contact_cnt);
          this.dataOutline[2].number = toThousands(data.negative_feedback_cnt);
          this.xAxisData = [];
          this.totalCustom = [];
          this.newContactCnt = [];
          this.negativeFeedbackCnt = [];
          data.list.forEach((element) => {
            this.xAxisData.push(element.date);
            this.totalCustom.push(element.customer_total);
            this.newContactCnt.push(element.new_contact_cnt);
            this.negativeFeedbackCnt.push(element.negative_feedback_cnt);
          });
          this.drawLineEcharts(this.activeTab);
        } else {
          this.$toast(res.data.msg || "获取数据失败");
        }
      });
    },
    choseTime(time) {
      this.timeActive = time;
      this.get_chart_data();
    },
    choseTab() {
      this.get_chart_data();
    },
    drawLineEcharts(index) {
      let chartDom = document.getElementById(`main${index}`);
      if (chartDom.hasAttribute("_echarts_instance_")) {
        chartDom.removeAttribute("_echarts_instance_");
      }
      let myChart = echarts.init(chartDom);
      this.$nextTick(() => {
        myChart.resize();
      });
      let option;
      let legendData = [
        {
          name: "客户总数",
          icon: this.chartIcon[0],
        },
        {
          name: "新增客户",
          icon: this.chartIcon[1],
        },
        {
          name: "流失客户",
          icon: this.chartIcon[2],
        },
      ];
      option = {
        xAxis: {
          type: "category",
          data: this.xAxisData,
        },
        yAxis: {
          type: "value",
        },
        legend: {
          itemHeight: 18,
          itemWidth: 18,
          data: legendData,
          x: "left",
          y: "top",
          padding: [28, 0, 20, 16],
          textStyle: {
            fontSize: 14,
          },
        },
        grid: {
          top: "20%",
          left: "4%",
          right: "8%",
          bottom: "8%",
          containLabel: true,
        },
        series: [
          {
            name: "客户总数",
            data: this.totalCustom,
            type: "line",
            smooth: true,
          },
          {
            name: "新增客户",
            data: this.newContactCnt,
            type: "line",
            smooth: true,
          },
          {
            name: "流失客户",
            data: this.negativeFeedbackCnt,
            type: "line",
            smooth: true,
          },
        ],
      };
      option && myChart.setOption(option, true);
      option.tooltip = {
        trigger: "axis",
      };
      myChart.setOption(option);
      myChart.on("legendselectchanged", (obj) => {
        legendData.forEach((e, index) => {
          if (e.name === obj.name) {
            e.icon = obj.selected[obj.name]
              ? this.chartIcon[index]
              : "image://" + require("@/assets/images/none_chose.png") + "";
          }
        });
        myChart.setOption(option);
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.tab_content {
  padding: 0.32rem;
}
.statistics-data-card {
  background-color: #ffffff;
  padding: 0.48rem 0.32rem;
  border-radius: 0.16rem;
  .data-title-box {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .data-title {
    font-size: 0.32rem;
    font-weight: 600;
    color: #333333;
    line-height: 0.48rem;
  }
  .data-time {
    font-size: 0.28rem;
    color: #999999;
    line-height: 0.4rem;
    cursor: pointer;
    .time-chosen {
      color: #1472ff;
    }
    .time-split {
      color: #333333;
    }
  }
  .data-outline {
    margin-top: 0.72rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .outline-item {
    display: flex;
    flex-direction: column;
  }
  .item-number {
    margin-top: 0.16rem;
    font-size: 0.4rem;
    font-family: D-DIN-Bold, D-DIN;
    font-weight: bold;
    color: #333333;
    line-height: 0.56rem;
  }
  .item-name {
    font-size: 0.28rem;
    font-weight: 400;
    color: #333333;
    line-height: 0.4rem;
  }
}
.chart-container {
  background-color: #ffffff;
  margin-top: 0.4rem;
  border-radius: 0.16rem;
  .charts-tabs {
    padding: 0.32rem;
    padding-bottom: 0;
    height: 0.48rem;
    width: auto;
    display: flex;
    align-items: center;
    cursor: pointer;
    .tabs-button {
      text-align: center;
      width: 1.28rem;
      height: 0.48rem;
      background: #f5f5f5;
      color: #b2b2b2;
      line-height: 0.48rem;
      font-size: 0.2rem;
      &.active-tab {
        background: #ffffff;
        border-radius: 0.08rem;
        border: 1px solid #cccccc;
      }
    }
  }
}
.data-line-pic {
  height: 7rem;
}
</style>
<style lang="scss">
.statistics {
  .van-tabs__line {
    background-color: #1472ff;
  }
  .van-tab__text {
    font-size: 0.32rem;
    font-weight: 400;
    color: #666666;
    line-height: 0.48rem;
  }
  .van-tab--active {
    font-weight: 600;
    color: #333333;
  }
}
</style>
